import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AppConfig } from './configs/app.config';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from './app.url';
import { AlertService } from './services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppConfig implements OnInit {

  constructor(
    private _primeNg: PrimeNGConfig,
    private _auth: AuthService,
    private data: DataService,
    private aActivatedRoutec: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) {
    super();
    // console.log(this._auth.accessToken);

    // โหลดข้อมูลผู้ใช้งานมาตั้งแต่เริ่มระบบ
    if (this._auth.accessToken)
      this._auth.initalizeLoadUserLogin().subscribe();
  }


  ngOnInit() {
    if (window.location.hostname != 'app.bizsoft.live') {
      this.alert.dialog('Develop mode !!!!! </br> URL Product  </br> https://app.bizsoft.live/')
    }

    this._primeNg.ripple = true;
    this.aActivatedRoutec.queryParams.subscribe(res => {
      let page: string = res['page'];



      if (page) {
        if (page.split("payment-fail")[0] == "") if (!this.data.paymentItems) window.close();
        if (page.split("payment-success")[0] == "") {
          this.router.navigate(['/', AppURL.authen], { queryParams: { page: 'success' } })
        }
      }

    })
  }
}
