import { environment } from 'src/environments/environment';
import { AppURL } from '../app.url';
import { MenulistURL } from '../menu/menu-list.url';
import { AccountURL } from '../modules/account/account.url';
import { AuthenURL } from '../modules/authen/authen.url';


export class AppConfig {
  /** origin url */
  originURL = environment.origin;

  /**  root url */
  AppURL = AppURL;

  /** account url */
  AccountURL = AccountURL;

  /** account url */
  AuthenURL = AuthenURL;


  //**menu list */
  Menulist = MenulistURL;
}
