import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";

@Injectable()
export class HttpConfig implements HttpInterceptor {

    constructor(private _auth: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appendURL = /http|https/.test(req.url) ? req.url : `${environment.apiURL}${req.url}`;
        return next
            .handle(req.clone({
                headers: this.appendHeaders(req.headers),
                url: appendURL,
            }))
            .pipe(map(c => this.handleResponse(c)))
            .pipe(catchError(c => this.handleException(c)));
    }

    /** handle response data */
    private handleResponse(response: HttpEvent<any>): any {
        if (response instanceof HttpResponse) {
            if (response.body.Token) {
                this._auth.setAccessToken(response.body);
                delete response.body.Token;
                delete response.body.Expires;

            }
        }
        return response;
    }


    /** append headers */
    private appendHeaders(headers: HttpHeaders) {
        if (this._auth.accessToken) headers = headers.append('Authorization', 'Bearer ' + this._auth.accessToken);
        return headers;
    }

    /** handle error exception */
    private handleException(res: HttpErrorResponse) {
        const _res: any = res;
        if (res.error && (res.error.message || res.error.Message)) {
            _res.message = res.error.message || res.error.Message;
        }
        // Unauthorization
        if (res.status == 401) {
            this._auth.removeAccessToken();
            location.reload();
        }
        return throwError(_res);
    }

}