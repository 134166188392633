import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExceptionModule } from './modules/exception/exception.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfig } from './configs/http.config';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { HistoryComponent } from './modules/history/history.component';
import { PasswordModule } from 'primeng/password';
import { OtpVerificationComponent } from './shareds/otp-verification/otp-verification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MarketingComponent } from './modules/marketing/marketing.component';
import { ContactComponent } from './modules/contact/contact.component';
import { BillingComponent } from './modules/billing/billing.component';
import { RoleComponent } from './modules/role/role.component';
import { ProcurementComponent } from './modules/procurement/procurement.component';
import { AccountingComponent } from './modules/accounting/accounting.component';
import { InvventoryComponent } from './modules/invventory/invventory.component';
import { DecimalPipe } from '@angular/common';












@NgModule({
  declarations: [
    AppComponent,
    HistoryComponent,
    OtpVerificationComponent,
    MarketingComponent,
    ContactComponent,
    BillingComponent,
    RoleComponent,
    AccountingComponent,
    ProcurementComponent,
    InvventoryComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ExceptionModule,
    HttpClientModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    NgOtpInputModule,
    Ng2TelInputModule,
    PasswordModule,

  ],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfig,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
