import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/** ข้อมูลที่ใช้งานบ่อยๆ หรือข้อมูลที่แชร์ร่วมกัน */
@Injectable({ providedIn: 'root' })
export class DataService {
  public sale_order_no: any;
  public paymentItems: any;
  public loadingPage = new Subject();
  public innerWidth: any;
  constructor() { }

  get getinnerWidth() {
    return this.innerWidth;
  }
}
