import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

/** การแจ้งเตื่อน */
@Injectable({ providedIn: 'root' })
export class AlertService {

  /** List of summary message */
  private notifySummaries = {
    success: 'แจ้งเตือนสำเร็จ',
    info: 'แจ้งเตือนจากระบบ',
    warn: 'แจ้งเตือนให้ตรวจสอบ',
    error: 'แจ้งเตือนข้อผิดพลาด'
  };

  constructor(
    private _message: MessageService,
    private _confirm: ConfirmationService,
  ) { }

  /** แจ้งเตือนธรรมดา */
  notify(message: string, severity: 'success' | 'info' | 'warn' | 'error' = 'error') {
    this._message.add({
      summary: this.notifySummaries[severity],
      severity: severity,
      detail: message
    });
  }

  /** แจ้งเตือนยืนยันการทำรายการต่อไป */
  confirm(message: string, header: string = 'ต้องการทำรายการต่อไป ?') {
    return new Promise(resolve => {
      this._confirm.confirm({
        key: 'confirm',
        header: header,
        acceptLabel: 'ต้องการ',
        rejectLabel: 'ไม่ต้องการ',
        message: message,
        acceptButtonStyleClass: 'p-button-sm p-button-rounded',
        rejectButtonStyleClass: 'p-button-sm p-button-rounded',
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }

  /** แจ้งเตือนจากระบบ dialog */
  dialog(message: string, header: string = 'แจ้งเตือนจากระบบ') {
    return new Promise(resolve => {
      this._confirm.confirm({
        key: 'dialog',
        header: header,
        acceptLabel: 'ปิดหน้านี้',
        message: message,
        dismissableMask: true,
        accept: () => resolve(true),
      });
    });
  }
}
