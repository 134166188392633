/** authen routing  */
export const AuthenURL = {
  authen: '',
  user: 'user',
  setting: 'setting',
  Invoice: 'Invoice',
  product: 'products&services',
  contact: 'contact',
  content: 'content',
  marketing: 'marketing',
  billing: 'billing',
  role: 'role',
  accounting: 'accounting',
  procurement: 'procurement',
  inventory: 'inventory'
};
