import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
import { AppURL } from '../../app.url';
import { AccountURL } from '../../modules/account/account.url';


@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {

  constructor(private _router: Router,
    private http: HttpClient,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute) { }
  signup: any;
  subAction: any;
  ngOnInit(): void {
    this.subAction = this.activatedRoute.snapshot.params['sub'];
    let local: any = localStorage.getItem(`${this.subAction}`);
    this.signup = JSON.parse(local);
    this.requestOtp();
  }

  reqotp: boolean = false;
  countdown: number = 30;
  Ref: any;
  requestOtp() {
    this.reqotp = true;
    let model = {
      CountryCode: this.signup.CountryCode,
      PhoneNumber: this.signup.PhoneNumber
    }

    if (this.subAction == 'signup') this.http.post(`/api/MSAuthentication/OTP/Request`, model).subscribe((res: any) => {
      this.signup.OTPRef = res.data.OTPRef;
      this.countdownTime();
    })

    else this.http.post(`/api/MSAuthentication/forgotpassword/otp/send`, model).subscribe((res: any) => {
      this.signup.OTPRef = res.data.OTPRef;
      this.countdownTime();
    })
  }

  countdownTime() {
    if (this.reqotp && this.countdown > 0) {
      this.countdown--;
      if (this.countdown > 0) {
        setTimeout(() => this.countdownTime(), 1000);
      } else {
        this.reqotp = false;
        this.countdown = 30;
      }

    }
  }

  otp: any;
  onOtpChange(v: any) {
    this.signup.OTPCode = v;
  }



  Verification() {
    if (this.subAction == 'signup') this.http.post(`/api/MSAuthentication/SignUp/Password`, this.signup).subscribe(res => {
      localStorage.removeItem('signup')
      this.alert.notify('Successful registration', 'success');
      this._router.navigate(['/', AppURL.account, AccountURL.signin])
    }, err => this.alert.notify(err.message))

    else this.http.post(`/api/MSAuthentication/forgotpassword/otp/verify`, this.signup).subscribe((res: any) => {
      // localStorage.removeItem('signup')
      this.alert.notify('Successful registration', 'success');
      this._router.navigate(['/', AppURL.account, AccountURL.forgotPassword, res.message])
    }, err => this.alert.notify(err.message))
  }

}
