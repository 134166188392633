<div class="row">
    <div class="col-12 text-center">
        <h1><b>Verification</b></h1>
        Enter the OTP code we sent you
        <br>
        <br *ngIf="!signup.OTPRef">
        <p *ngIf="signup.OTPRef"><b>Ref</b>:{{signup.OTPRef}}</p>
        <ng-otp-input allowNumbersOnly="" class="box-number" (onInputChange)="onOtpChange($event)" [config]="{length:4,allowNumbersOnly:true}"></ng-otp-input>
        <br>
    </div>
    <div class="col-12 text-center">
        <p class="text-otp" *ngIf="!reqotp" (click)="requestOtp()">
            Request OTP
        </p>
        <p *ngIf="reqotp">
            Resend in {{countdown}}
        </p>
    </div>
    <div class="col-12">
        <button pRipple class="mt-2 mb-3 p-button-rounded w-100" pButton type="submit" label="Submit" (click)="Verification()"></button>
    </div>
</div>