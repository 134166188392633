import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invventory',
  templateUrl: './invventory.component.html',
  styleUrls: ['./invventory.component.scss']
})
export class InvventoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
